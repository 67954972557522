import qs from 'qs'
import { request } from './common'
import { OFFER_TYPE_TOUR_V2 } from '../constants/offer-constants'

export default class OrderService {
  static getCompletedVendorPurchases({
    page = 1,
    limit = 15,
    vendorId,
    offerId,
    addonOpportunityId,
    bookingNumbers,
    departureDate,
    type,
    customerSearch,
    showCancelled = true,
    showBNBL = false,
    idOrders,
  } = {}) {
    const requestParams = {
      status: [
        'completed',
        'pending',
        'refunded',
      ],
      page: page,
      per_page: limit,
      item_status: [
        'completed',
      ],
    }

    if (showCancelled) {
      requestParams.item_status.push('cancelled')
    }

    if (type === 'tour') {
      requestParams.item_status.push('awaiting_dates')
    }

    if (vendorId) {
      requestParams.vendor_id = vendorId
    }

    if (showBNBL) {
      requestParams.item_status.push('awaiting_dates')
    }

    if (offerId) {
      requestParams.offer_id = offerId
    }

    if (addonOpportunityId) {
      requestParams.addon_opportunity_id = addonOpportunityId
    }

    if (bookingNumbers) {
      requestParams.booking_numbers = bookingNumbers.join(',')
    }

    if (departureDate) {
      requestParams.departure_date = departureDate
    }

    if (type) {
      requestParams.type = type
    }

    if (customerSearch) {
      requestParams.q = customerSearch
    }

    if (idOrders) {
      requestParams.orders_id = idOrders
    }

    requestParams.status = requestParams.status.join(',')
    requestParams.item_status = requestParams.item_status.join(',')

    return request(`${window.configs.COMMERCE_HOST}/api/orders/?${qs.stringify(requestParams)}`, { method: 'GET' }).then((response) => {
      if (type === OFFER_TYPE_TOUR_V2) {
        return response
      }

      // TODO Remove dirty hack when multi items is supported
      response.result = response.result.filter(order => order.items.length > 0).map((order) => {
        const item = order.items[0]

        order.booking_number = item.booking_number
        order.reservation = item.reservation
        order.fk_offer = item.fk_offer

        return order
      })

      return response
    })
  }

  static async getReservationHistory(
    orderID,
    itemID,
    vendorID,
    page,
    limit,
  ) {
    const url = `${window.configs.COMMERCE_HOST}/api/orders/${orderID}/items/${itemID}/reservation-history?page=${page}&limit=${limit}&vendor_id=${vendorID}`

    const response = await request(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    return response.result
  }

  static getAddons({
    page = 1,
    perPage = 15,
    vendorId,
    offerId,
    addonOpportunityId,
    bookingNumbers,
    customer,
    unlogged,
    showCancelled = true,
  } = {}) {
    const requestParams = {
      status: [
        'completed',
        'pending',
        'refunded',
      ],
      page: page,
      per_page: perPage,
      item_status: [
        'completed',
      ],
    }

    if (showCancelled) {
      requestParams.item_status.push('cancelled')
    }

    if (vendorId) {
      requestParams.vendor_id = vendorId
    }

    if (offerId) {
      requestParams.offer_id = offerId
    }

    if (bookingNumbers) {
      requestParams.booking_numbers = bookingNumbers.join(',')
    }

    if (customer) {
      requestParams.customer = customer
    }

    if (unlogged) {
      requestParams.unlogged = 'true'
    }

    requestParams.status = requestParams.status.join(',')
    requestParams.item_status = requestParams.item_status.join(',')

    return request(`${window.configs.COMMERCE_HOST}/api/orders/addons/${addonOpportunityId}?${qs.stringify(requestParams)}`, { method: 'GET' }).then((response) => response)
  }

  static getVendorRefundRequests({ vendorId, page = 1, limit = 10 } = {}) {
    const requestParams = {
      vendorId: vendorId,
      page: page,
      limit: limit,
    }

    return request(`${window.configs.COMMERCE_HOST}/api/orders/refund-request?${qs.stringify(requestParams)}`, { method: 'GET' }).then((response) => response)
  }

  static async patchRefundRequest(url, op, status) {
    const response = await request(url, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        status,
        op,
      }),
    })
    return response.result
  }

  static async updateRefundRequest({ refundRequestId, status }) {
    const url = `${window.configs.COMMERCE_HOST}/api/orders/refund-request/${refundRequestId}`

    return this.patchRefundRequest(url, 'update_status', status)
  }
}
