import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

import { Navbar } from 'react-bootstrap'
import { LoginLink, LogoutLink, NotAuthenticated, Authenticated } from 'react-stormpath'
import LuxNews from './LuxNews'

export default class Header extends React.Component {
  render() {
    const welcomeMessage = this.context.user && this.props.vendor ? (
      <span className="welcome-msg">
        Welcome back, {this.props.vendor.name}
      </span>
    ) : null

    const homeLink = this.props.vendor ? `/${this.props.vendor.sfid}/offers` : '/'
    const remitLink = this.props.vendor ? `/${this.props.vendor.sfid}/remittance/` : '/'
    const addonsLink = this.props.vendor ? `/${this.props.vendor.sfid}/addons` : '/'
    const datesRequestLink = this.props.vendor ? `/${this.props.vendor.sfid}/dates-requests` : '/'
    const cancellationRequestsLink = this.props.vendor ? `/${this.props.vendor.sfid}/cancellation-requests` : '/'

    return (
      <Navbar className="navbar navbar-default navbar-static-top">
        <div className="container">
          <Navbar.Header>
            <Navbar.Brand>
              <Link to={homeLink} activeClassName="active" onlyActiveOnIndex><img style={{ height: '30px' }} src="/assets/logo.svg" alt="logo" /></Link>
              {welcomeMessage}
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <ul className="nav navbar-nav navbar-left">
              <Authenticated>
                <li>
                  <Link to={homeLink} activeClassName="active">Campaigns</Link>
                </li>
              </Authenticated>
              <Authenticated>
                <li>
                  <Link to={addonsLink} activeClassName="active">Experiences</Link>
                </li>
              </Authenticated>
              <Authenticated>
                <li>
                  <Link to={datesRequestLink} activeClassName="active">Booking requests</Link>
                </li>
              </Authenticated>
              <Authenticated>
                <li>
                  <Link to={cancellationRequestsLink} activeClassName="active">Cancellation requests</Link>
                </li>
              </Authenticated>
            </ul>

            <ul className="nav navbar-nav navbar-right">
              <NotAuthenticated>
                <li>
                  <LoginLink activeClassName="active" />
                </li>
              </NotAuthenticated>
              {window.configs.NEW_VENDOR_REMITTANCE_DOWNLOAD === 'on' && (
                <Authenticated>
                  <li>
                    <Link to={remitLink} activeClassName="active">Remittances</Link>
                  </li>
                </Authenticated>
              )}
              <Authenticated>
                <li>
                  <LuxNews />
                </li>
              </Authenticated>
              <Authenticated>
                <li>
                  <Link to="/profile" activeClassName="active">My Account</Link>
                </li>
              </Authenticated>
              <Authenticated>
                <li className="logout-link">
                  <LogoutLink />
                </li>
              </Authenticated>
            </ul>
          </Navbar.Collapse>
        </div>
      </Navbar>
    )
  }
}

Header.contextTypes = {
  user: PropTypes.object,
}
