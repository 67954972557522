import 'whatwg-fetch'
import fetch from 'isomorphic-fetch'

export function request(destination, options) {
  if (!options) {
    options = {}
  }
  options.credentials = 'include'

  const authRequired = window._api_paths_.some(r => (r.test(destination)))
  if (authRequired === true && window._auth_token_) {
    options.headers = Object.assign(
      options.headers || {},
      { authorization: `Bearer ${window._auth_token_}` },
    )
  }

  return fetch(destination, options).then(function(response) {
    if (!response.ok) {
      if (response.status === 403) {
        throw new Error("You don't have the permissions for this operation")
      } else if (response.status === 401 || response.status === 400) {
        const message = 'You are not authorised to perform this operation'
        return response.json().then((result) => {
          throw new Error(result.message)
        })
          .catch((err) => {
            throw new Error(err.message || message)
          })
      } else {
        const message = `Network error received: ${response.status}`
        return response.json().then((result) => {
          throw new Error(result.message)
        })
          .catch((err) => {
            throw new Error(err.message || message)
          })
      }
    } else {
      return response.status === 204 ? response : response.json()
    }
  })
}

export const formHeaders = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }

export function objectToFormData(object) {
  return Object.keys(object).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(object[key])).join('&')
}
