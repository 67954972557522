import './scss/main.scss'

// hijack XMLHttpRequest api here so we can inject auth headers if needed
window._api_paths_ = []
for (const i in window.configs) {
  if (new RegExp('_HOST$').test(i)) {
    window._api_paths_.push(new RegExp(`^${window.configs[i]}`))
  }
}

const originalXHRopen = window.XMLHttpRequest.prototype.open
window.XMLHttpRequest.prototype.open = function() {
  const res = originalXHRopen.apply(this, arguments)
  if (
    new RegExp(`^${window.configs.AUTH_HOST}`).test(arguments[1]) &&
    new RegExp('/login$').test(arguments[1])
  ) {
    this._pluck_token = true
  } else {
    const authRequired = window._api_paths_.some(r => (r.test(arguments[1])))
    if (authRequired && window._auth_token_) {
      this.setRequestHeader('authorization', `Bearer ${window._auth_token_}`)
    }
  }
  return res
}

const originalXHRsend = window.XMLHttpRequest.prototype.send
window.XMLHttpRequest.prototype.send = function() {
  if (this._pluck_token === true) {
    this._original_onreadystatechange = this.onreadystatechange
    this.onreadystatechange = () => {
      if (this.readyState === this.DONE && this.status === 200) {
        const resp = JSON.parse(this.response)
        window._auth_token_ = resp.result.access_token
      }
      this._original_onreadystatechange.apply(this, arguments)
    }
  }
  return originalXHRsend.apply(this, arguments)
}

/* eslint-disable import/first */
import React from 'react'
import ReactDOM from 'react-dom'
import { IndexRoute, Route, browserHistory } from 'react-router'
import ReactStormpath, { Router, HomeRoute, LoginRoute, AuthenticatedRoute } from 'react-stormpath'
import OffersPageContainer from './components/Offers/OffersPageContainer'
import OrdersPageContainer from './components/Orders/OrdersPageContainer'
import AddonOrdersPageContainer from './components/AddonOrders/PageContainer'
import AvailabilityContainer from './components/Properties/AvailabilityContainer'
import IndexPage from './components/IndexPage'
import MasterPage from './components/Common/MasterPage'
import LoginPage from './components/Profile/LoginPage'
import ForgotPasswordPage from './components/Profile/ForgotPasswordPage'
import ResetPasswordPage from './components/Profile/ResetPasswordPage'
import ProfilePage from './components/Profile/ProfilePage'
import ViewRemittance from './components/Remittance/ViewRemittance'
import AddonsPageContainer from './components/Addons/AddonsPageContainer'
import DatesRequests from './components/Orders/DatesRequests'
import CancellationRequests from './components/Orders/CancellationRequests'
import * as Sentry from '@sentry/browser'
/* eslint-enable import/first */

ReactStormpath.init({
  endpoints: {
    baseUri: window.configs.AUTH_HOST,
  },
})

const releaseVersion = document
  .getElementById('app-container')
  .getAttribute('data-release-version')

const appEnvironment = document
  .getElementById('app-container')
  .getAttribute('data-app-env')

if (
  appEnvironment.startsWith('test') ||
  appEnvironment.startsWith('production')
) {
  Sentry.init({
    dsn: 'https://28353de23be342e0b3113607dc091855@o273699.ingest.sentry.io/6040444',
    environment: appEnvironment,
    release: releaseVersion || 'unreleased',
  })
}

ReactDOM.render(
  <Router history={browserHistory}>
    <HomeRoute path="/" component={MasterPage}>
      <IndexRoute component={IndexPage} />
      <LoginRoute path="/login" component={LoginPage} />
      <Route path="/forgotpassword" component={ForgotPasswordPage} />
      <Route path="/reset-password/:reset_token" component={ResetPasswordPage} />
      <AuthenticatedRoute>
        <Route path="/:id_vendor/offers" component={OffersPageContainer} />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <Route path="/:id_vendor/orders" component={OrdersPageContainer} />
        <Route path="/:id_vendor/:id_offer/orders" component={OrdersPageContainer} />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <Route path="/:id_vendor/addons" component={AddonsPageContainer} />
        <Route path="/:id_vendor/addons/:id_addon/orders" component={AddonOrdersPageContainer} />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <Route path="/:id_vendor/properties/:id_property/availability" component={AvailabilityContainer} />
        <Route path="/:id_vendor/properties/:id_property/room-types/:id_room_type/availability" component={AvailabilityContainer} />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <Route path="/profile" component={ProfilePage} />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <Route path="/:id_vendor/remittance" component={ViewRemittance} />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <Route path="/:id_vendor/dates-requests" component={DatesRequests} />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <Route path="/:id_vendor/cancellation-requests" component={CancellationRequests} />
      </AuthenticatedRoute>
    </HomeRoute>
  </Router>,
  document.getElementById('app-container'),
)
